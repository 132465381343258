import { SelectOptionsType } from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import { useUserProfilesQuery } from '@/graphql'
import { useMemo } from 'react'

const useParticipantsOptions = (approvalSetUserProfilesId: string[]): {approvalItemUserProfiles: UserProfileEntity[], options: SelectOptionsType[], loading: boolean} => {
  const { data, loading } = useUserProfilesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { id: { in: approvalSetUserProfilesId } } },
  })
  const approvalItemUserProfiles = data?.userProfiles?.data as UserProfileEntity[]

  const options: SelectOptionsType[] = useMemo(()=> {
    return  approvalItemUserProfiles?.map(it => ({
      label: <SelectUserItem name={it?.attributes?.fullName} email={it?.attributes?.primaryEmail}  />,
      value: it?.id,
    })) as SelectOptionsType[]
  },[approvalItemUserProfiles])

return {approvalItemUserProfiles, options, loading }
}

export default useParticipantsOptions