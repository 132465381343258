import { useAuth } from '@/components/context/AuthProvider'
import { Loader } from '@/components/layout/Loader'
import SidebarNew from '@/components/sidebar-new/SidebarNew'
import { USER_TOKEN } from '@/helpers/auth'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Layout } from 'antd'
import classnames from 'classnames'
import { FC, Suspense, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import './index.less'
import { useLocation } from 'react-router-dom'


export const globalFetchPolicy = 'no-cache'
const DashboardLayout: FC = () => {
  const {user, getUser} = useAuth()
  const params = useParams()
  const currentOrgSlug = params.org || EASETECH_COMPANY
  const navigate = useNavigate()
  const {pathname} = useLocation()

  useEffect(() => {
    if(pathname ==='/dashboard/application/approval/') {
      setIsCollapsed(true)
    }
  }, [pathname])

  const [collapsed, setIsCollapsed] = useState(false)

  useEffect(() => {
    const checkUser = async () => {
      const checkedUser = await getUser()

      if(!checkedUser) {
        localStorage.removeItem(USER_TOKEN)
        navigate(`/${currentOrgSlug}/home`)
      }
    }
    if(!user) {
      checkUser().then()
    }
  }, [currentOrgSlug, navigate, user]) //Do not put 'getUser' here (infinite rerender)


  const classes = classnames('dashboard-layout', {
    'dashboard-layout-collapsed': collapsed,
  })
  

  return (
    <Layout style={{ minHeight: '100vh' }} className={'platform-layout'}>
      <SidebarNew collapsed={collapsed} setIsCollapsed={setIsCollapsed} />
      <Layout className={classes}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Layout>
    </Layout>
  )
}
export { DashboardLayout }
