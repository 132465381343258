import { getInitials } from '@/helpers/getInitials'
import { Col, Row } from 'antd'
import { FC } from 'react'
import Typography from '../typography/Typography'
import './SelectUserItem.less'
import classnames from 'classnames'

const {Text, Paragraph } = Typography
type Props = {
  name: Maybe<string>
  email: Maybe<string>
  avatar?: Maybe<string>
  isBordered?: boolean
  marginBottom?: number
}
const SelectUserItem:FC<Props> = ({name, email, avatar, isBordered = false, marginBottom}) => {
  const initials = getInitials(name || 'UU')
  const cardClasses = classnames({
    'user-select-item': true,
    'is-bordered': isBordered,
  })
  return (
    <Row className={cardClasses} align={'middle'} style={{marginBottom}}>
        <div className={'user-item-avatar'}>
          {avatar ? <img src={avatar} alt={'avatar'} /> : <Text size={'xs'} weight={'w700'}>{initials}</Text>}
        </div>
      <div className={'user-item-content'}>
            <Paragraph weight={'w700'} style={{margin: 0}}>{name}</Paragraph>
            <Paragraph className={'secondary-text'} size={'xs'} color={'light-gray'} style={{ margin: 0, lineHeight: 1 }}>{email}</Paragraph>
      </div>

    </Row>
  )
}

export default SelectUserItem
