import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import CustomUpload from '@/components/custom-upload/CustomUpload'
import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import { SvgIcon } from '@/components/icon'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import useSaveRecordAsDraft from '@/components/modal/modal-content/hooks/useSaveKickoffRecord'
import Status from '@/components/status/Status'
import {
  useApplicationsQuery,
  useDiscussionTopicsQuery,
  useKickoffInviteLazyQuery,
  useKickoffInvitesQuery,
  useKickoffRecordQuery,
  useSendRecordMutation,
  useUpdateKickoffRecordMutation,
} from '@/graphql'
import useApprovalSetUserProfiles from '@/hooks/useApprovalSetUserProfileIds'
import useParticipantsOptions from '@/hooks/useParticipantsOptions'
import { DefaultFileListItem } from '@/types/upload'
import { Col, Form, Popconfirm, Row, Skeleton, Space } from 'antd'
import { Feature, Point } from 'geojson'
import moment from 'moment'
import { FC, Ref, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapRef, Marker, MarkerDragEvent } from 'react-map-gl'
import { useSearchParams } from 'react-router-dom'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const CreateKickoffRecord: FC = () => {
  const { user, role } = useAuth()
  const mapRef = useRef<MapRef>()
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string

  const { setIsModalOpen, currentId, setModalWidth, setCurrentId, setIsDrawerOpen, setDrawerType } = useGeneralContext()

  const [updateRecord, { loading: recordUpdating }] = useUpdateKickoffRecordMutation()
  const [sendRecord, { loading: sendingRecord }] = useSendRecordMutation()


  const [location, setLocation] = useState<Point | null>(null)
  const [uploadFilesIds, setUploadFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [uploadApprovalFilesIds, setUploadApprovalFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [defaultFileList, setDefaultFileList] = useState<DefaultFileListItem[]>([])
  const [defaultApprovalFileList, setDefaultApprovalFileList] = useState<DefaultFileListItem[]>([])
  const [selectedInviteId, setSelectedInviteId] = useState<Maybe<string>>('')
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<Maybe<string>[]>([])
  const [isCurrentKickoffDisabled, setIsCurrentKickoffDisabled] = useState(false)

  const [fetchInvite] = useKickoffInviteLazyQuery()
  const { data, loading: applicationLoading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { data: currentKickoffData, loading: currentKickoffLoading } = useKickoffRecordQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const currentKickoff = currentKickoffData?.kickoffRecord?.data as KickoffRecordEntity
  const appData = data?.applications?.data?.[0] as ApplicationEntity
  const itemId = currentKickoff?.id
  const applicationCenterFeature = appData?.attributes?.project?.data?.attributes?.projectCenter as Feature
  const applicationCenter = applicationCenterFeature?.geometry as Point

  useEffect(() => {
    setIsCurrentKickoffDisabled(currentKickoff?.attributes?.status === 'accepted' || currentKickoff?.attributes?.status === 'sent')
  }, [currentKickoff?.attributes?.status])

  useEffect(() => {
    if (currentKickoff) {
      const defaultFiles = currentKickoff?.attributes?.photos?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      const defaultApprovalFiles = currentKickoff?.attributes?.approvalDocuments?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      setUploadFilesIds(currentKickoff?.attributes?.photos?.data?.map(it => it?.id))
      setUploadApprovalFilesIds(currentKickoff?.attributes?.approvalDocuments?.data?.map(it => it?.id))

      setDefaultFileList([...defaultFiles])
      setDefaultApprovalFileList([...defaultApprovalFiles])
      setSelectedInviteId(currentKickoff?.attributes?.kickoffInvite?.data?.id)
    }
  }, [currentKickoff])

  const { data: discussionTopicsData, loading: topicsLoading } = useDiscussionTopicsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {filter: {category: {eq: 'record'}}}
  })

  const { data: kickoffInvitesData, loading: invitesLoading } = useKickoffInvitesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        application: { uuid: { eq: uuid } },
      },
    },
  })

  const discussionTopics = discussionTopicsData?.discussionTopics?.data
  const topicsOptions: SelectOptionsType[] = discussionTopics?.map(it => ({
    label: it?.attributes?.title || '',
    value: it?.id || '',
  })) || []

  useEffect(() => {
    if (!applicationLoading && applicationCenter && !currentKickoffLoading && !currentKickoff) {
      setLocation(applicationCenter)
    }
    if (!currentKickoffLoading && currentKickoff) {
      setLocation(currentKickoff?.attributes?.location as Point)
    }
  }, [applicationCenter, applicationLoading, currentKickoff, currentKickoffLoading])

  const { onSaveAsDraft, loading } = useSaveRecordAsDraft({
    mapRef,
    appData,
    location,
  })
  const approvalSetUserProfilesId = useApprovalSetUserProfiles(appData)
  const { approvalItemUserProfiles, options: participantsOptions, loading: userProfilesDataLoading } = useParticipantsOptions(approvalSetUserProfilesId)
  const recipientsEmails = approvalItemUserProfiles?.filter(it => it?.id && selectedParticipantsIds?.includes(it?.id))?.map(it => it?.attributes?.primaryEmail)?.toString()?.split(',')?.join(', ')

  const onMarkerDrag = (e: MarkerDragEvent) => {
    setLocation({
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    })
  }
  const onLoad = () => {
    mapRef?.current?.setCenter(applicationCenter?.coordinates as LngLatLike)
    mapRef?.current?.easeTo({ zoom: 14 })
  }
  const onSaveAndSubmit = async () => {
    const values = await form.validateFields()
    const updatedRecordId = await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedInviteId, selectedParticipantsIds, 'sent')

    if (updatedRecordId) {
      await sendRecord({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            recordId: updatedRecordId as string,
            organizationId: user?.organization?.data?.id || '',
          },
        },
        onCompleted: () => {
          BlackNotification('Record sent successfully')
          setIsModalOpen(false)
          setModalWidth(null)

        },
        onError: (error) => {
          BlackNotification(error.message)
          setIsModalOpen(false)
        },
      })
    }
  }

  const handleSaveAsDraft = async (values: KickoffRecordInput) => {
    await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedInviteId, selectedParticipantsIds, 'draft')
    setIsModalOpen(false)
    setModalWidth(null)
  }
  const inviteOptions: SelectOptionsType[] = kickoffInvitesData?.kickoffInvites?.data?.map(it => ({
    label: `${it?.attributes?.date} (${moment(it?.attributes?.time, 'HH:mm SSS').format('HH:mm A')})`,
    value: it?.id || '',
  })) || []

  useEffect(() => {
    setSelectedParticipantsIds(currentKickoff ? currentKickoff?.attributes?.participants?.data?.map(it => it?.id) || [] : approvalSetUserProfilesId)
  }, [approvalSetUserProfilesId, currentKickoff])

  const initialValues = {
    kickoffInvite: currentKickoff?.attributes?.kickoffInvite?.data?.id,
    date: currentKickoff ? moment(currentKickoff?.attributes?.date, 'YYYY-MM-DD') : null,
    time: currentKickoff ? moment(currentKickoff?.attributes?.time, 'HH:mm SSS') : null,
    description: currentKickoff ? currentKickoff?.attributes?.description : null,
    participants: currentKickoff ? currentKickoff?.attributes?.participants?.data?.map(it => it?.id) : approvalSetUserProfilesId,
    discussionTopics: currentKickoff ? currentKickoff?.attributes?.discussionTopics?.data?.map(it => it?.id) : [],
  }

  const onConfirm = async () => {
    currentKickoff?.id && await updateRecord({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentKickoff?.id,
        data: {
          status: 'accepted',
        },
      },
      onCompleted: () => {
        BlackNotification('Record has been successfully accepted.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        BlackNotification(`Error occurred. ${error.message}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const handleBaseInviteChange = async (id: string) => {
    setSelectedInviteId(id)
    await fetchInvite({
      fetchPolicy: globalFetchPolicy,
      variables: { id },
      onCompleted: (data) => {
        const invite = data?.kickoffInvite?.data as KickoffInviteEntity

        form.setFieldsValue({
          date: moment(invite?.attributes?.date, 'YYYY-MM-DD'),
          time: moment(invite?.attributes?.time, 'HH:mm SSS'),
          description: invite?.attributes?.description,
          participants: invite?.attributes?.participants?.data?.map(it => it?.id),
        })
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }
  const onParticipantsChange = (idArray: string[]) => {
    setSelectedParticipantsIds(idArray)
  }

  const onAddContact = () => {
    setIsDrawerOpen(true)
    setDrawerType('create-profile')
    setCurrentId(user?.userProfile?.data?.attributes?.company?.data?.id || '')
  }

  const locationPoint = {
    'type': 'Feature',
    'geometry': location,
  } as Feature<Point>
  return applicationLoading || currentKickoffLoading || topicsLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <>
      <FormItemDivider
        title={currentKickoff ? 'Edit Kickoff record' : 'Create Kickoff record'}
        subTitle={`${currentKickoff ? 'Edit' : 'Create'} you kickoff record and send it to participants`}
        marginTop={0}
        marginBottom={10}
        extra={<Status status={currentKickoff?.attributes?.status ?? 'draft'} accented={true} />}
      />
      <Space direction={'vertical'} style={{ width: '100%', paddingTop: 8 }} size={'middle'} className={'big-modal-with-scroll-container'}>
        <Form
          layout={'vertical'}
          size={'middle'}
          onFinish={handleSaveAsDraft}
          form={form}
          initialValues={initialValues}
        >
          <FormItem
            name={'kickoffInvite'}
            type={'select'}
            placeholder={'Select base Invite'}
            label={'Base Kickoff Invite'}
            selectOptions={inviteOptions}
            required={false}
            handleSelectChange={handleBaseInviteChange}
            helpText={'Optionally you can choose previously created invite as a base for the current kickoff record.'}
            loading={invitesLoading}
          />
          <Row>
            <Col span={24}>
              <MapBoxMap
                mapRef={mapRef as Ref<MapRef>}
                isDrawTools={false}
                isGeometryVisibilityToggle={false}
                mapContainerHeight={300}
                onLoad={onLoad}
              >
                {location && (
                  <Marker
                    key={'center'}
                    longitude={location?.coordinates?.[0]}
                    latitude={location?.coordinates?.[1]}
                    draggable={true}
                    onDrag={onMarkerDrag}
                    style={{ opacity: 0 }} // to be able to drag marker but MarkerCircle will be rendered
                  />
                )}
                <MarkerCircle locationPoint={locationPoint} />
              </MapBoxMap>
              {!isCurrentKickoffDisabled && <Text>{'Drag marker if needed to specify the exact place of meeting.'}</Text>}
            </Col>
          </Row>

          <Row gutter={16} style={{ paddingTop: 24 }}>
            <Col span={12}>
              <FormItem name={'date'} type={'date-picker'} label={'Date of meeting'} readOnly={isCurrentKickoffDisabled} />
            </Col>
            <Col span={12}>
              <FormItem name={'time'} type={'time-picker'} label={'Time of meeting'} readOnly={isCurrentKickoffDisabled} />
            </Col>
          </Row>
          <FormItem
            name={'discussionTopics'}
            type={'multi-select'}
            placeholder={'Select key discussions'}
            label={'Key discussions'}
            selectOptions={topicsOptions}
            readOnly={isCurrentKickoffDisabled}
          />
          <FormItem name={'description'} type={'textarea'} placeholder={'Enter description'} rows={5} label={'Description/ Requirements'} readOnly={isCurrentKickoffDisabled} />
          <FormItem
            name={'participants'}
            type={'multi-select'}
            placeholder={'Select participants'}
            label={'Recipient'}
            selectOptions={participantsOptions}
            handleMultiChange={onParticipantsChange}
            loading={userProfilesDataLoading}
            helpText={'Inside recipients options you can find people selected as a "Site contacts" inside corresponding approval form.'}
          />
          <Row justify={'end'}>
            <Button btnType={'table-action'} icon={<SvgIcon type={'plus'} />} text={'Add contact'} onClick={onAddContact} />
          </Row>
          <Row style={{paddingTop: 24}}>
            <Col span={24}>
              <CustomUpload
                defaultFileList={defaultFileList}
                isUploadItemDisabled={isCurrentKickoffDisabled}
                setUploadFilesIds={setUploadFilesIds}
                isUploadDisabled={isCurrentKickoffDisabled}
                uploadButtonText={'Upload photos'}
              />
            </Col>
          </Row>

          {/*<FormItemDivider />*/}
          {/*<Button disabled loading={loading} text={'Generate kickoff letter'} btnType={'ghost'} icon={<SvgIcon type={'envelop'}  />} block={true} style={{marginTop: 20}} onClick={onGenerateKickoffLetter}/>*/}
          <FormItemDivider />
          <CustomUpload
            defaultFileList={defaultApprovalFileList}
            isUploadItemDisabled={isCurrentKickoffDisabled}
            setUploadFilesIds={setUploadApprovalFilesIds}
            isUploadDisabled={isCurrentKickoffDisabled}
            uploadButtonText={'Upload approval documents'}
          />
          {isCurrentKickoffDisabled ? (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button text={'Confirm & Accept'} btnType={'primary'} loading={recordUpdating} onClick={onConfirm}
                      disabled={isCurrentKickoffDisabled} />
            </Row>
          ) : (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button
                text={itemId ? 'Update Record' : 'Save as draft'}
                btnType={'text'}
                htmlType={'submit'}
                loading={loading}
                disabled={isCurrentKickoffDisabled}
              />
              <Popconfirm
                placement={'topRight'}
                title={<Col style={{ maxWidth: '300px' }}>
                  <Paragraph style={{ margin: 0 }}>{`Email with the Record details will be sent to the following email address: `}</Paragraph>
                  <Text weight={'w700'}>{recipientsEmails}</Text>
                </Col>}
                onConfirm={onSaveAndSubmit}
                okText={'Send'}
                cancelText={'Cancel'}
                cancelButtonProps={{ loading: sendingRecord }}
                okButtonProps={{ loading: sendingRecord }}
              >
                <Button
                  text={'Save and Submit record'}
                  btnType={'primary'}
                  loading={loading}
                  disabled={isCurrentKickoffDisabled}
                />
              </Popconfirm>
            </Row>
          )}
        </Form>
      </Space>
    </>
  )
}
export default CreateKickoffRecord
