import ApprovalReview from '@/components/modal/modal-content/ApprovalReview'
import CreateUserProfile from '@/components/modal/modal-content/CreateUserProfile'
import CreateCompany from '@/components/modal/modal-content/CreateCompany'
import CompanyInfo from '@/components/modal/modal-content/CompanyInfo'
import UserProfileReview from '@/components/modal/modal-content/UserProfileReview'
import { FC } from 'react'
import { useGeneralContext } from '../context/GeneralContext'

const DrawerContent: FC = () => {
  const { drawerType } = useGeneralContext()

  const modalContentGenerator = () => {
    switch (drawerType) {
      case 'create-profile':
        return <CreateUserProfile />

      case 'create-company':
        return <CreateCompany />

      case 'company-info':
        return <CompanyInfo />

      case 'approval-review':
        return <ApprovalReview />

      case 'profile-review':
        return <UserProfileReview />

      default:
        return <p>No drawer content ...</p>
    }
  }
  return <div className={'modal-content'}>{modalContentGenerator()}</div>
}

export default DrawerContent
