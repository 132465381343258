import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import ItemTag from '@/components/item-tag/ItemTag'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompaniesQuery, useSendCompaniesNotificationsMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Row, Skeleton, Space } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const FollowUp: FC = () => {
  const {user} = useAuth()
  const { setIsModalOpen } = useGeneralContext()

  const [companies, setCompanies] = useState<CompanyEntity[]>([])

  const [sendCompaniesNotifications, { loading: notificationIsSending }] = useSendCompaniesNotificationsMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const approvalSet = appData?.attributes?.approvalSet as ComponentDataApplicationServiceProvider[]
  const twoWeeksAgo = moment().subtract(2, 'weeks')

  const outdatedApprovalItems = approvalSet?.filter(it => (!it?.approved && !it?.feedback) || (!it?.approved && moment(it?.feedback).isBefore(twoWeeksAgo)))
  const companiesIds = outdatedApprovalItems?.length ? outdatedApprovalItems?.map(it => it?.company?.data?.id) : []

  const { data, loading: companiesDataLoading } = useCompaniesQuery({
    fetchPolicy: globalFetchPolicy,
    skip: companiesIds?.length === 0,
    variables: { filter: { id: { in: companiesIds } }, pagination: { limit: -1 } },
  })
  const companiesData = data?.companies?.data as CompanyEntity[]


  useEffect(() => {
    setCompanies(companiesData)
  }, [companiesData])

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onConfirm = async () => {
    const sentIds = companies?.map(it => it.id) as string[]
    sentIds && sentIds?.length && await sendCompaniesNotifications({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          companiesIds: sentIds,
          applicationId: appData?.id || '',
          organizationId: user?.organization?.data?.id || '',
          category: 'approval',
          profileId: user?.userProfile?.data?.id || '',
        },
      },
      onCompleted: () => {
        BlackNotification('Notifications were sent successfully.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
    })
  }

  const onItemClick = (id: Maybe<string>) => {
    const filteredCompanies = companies?.filter(it => it?.id !== id)
    setCompanies(filteredCompanies)
  }

  return (
    <>
      <FormItemDivider
        title={'Follow Up approvals'}
        subTitle={'Below is a list of companies that have not yet approved the current application, with feedback dates older than two weeks.'}
      />
      {!companiesIds?.length ? <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>{'No approval items to follow up'}</Paragraph> : (
        <>
          <Paragraph style={{ marginTop: 12 }} size={'sm'} weight={'w700'}>
            {`Notification email will be sent to all listed companies`}
          </Paragraph>

        </>

      )}
      {companiesDataLoading && (
        <Skeleton paragraph={{ rows: 5 }} loading={true} />
      )}
      <Space direction={'vertical'} className={'tag-list-container'}>
        {companies?.map(it => (
          <ItemTag
            key={it?.id}
            mainText={it?.attributes?.name}
            secondaryText={it?.attributes?.companyEmail}
            iconType={'envelop'}
            closable={true}
            onClick={onItemClick}
            itemId={it?.id}
          />
        ))}

      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
        />
        <Button
          text={'Send notifications'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={notificationIsSending || companiesDataLoading || appDataLoading}
          icon={<SvgIcon type={'envelop'} />}
          isIconWhite={true}
        />
      </Row>
    </>
  )
}
export default FollowUp
