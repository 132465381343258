import { useMemo } from 'react';
import moment from 'moment';

const useApprovalSetCopy = (approvalSet: Maybe<ComponentDataApplicationServiceProvider>[]): ComponentDataApplicationServiceProviderInput[] | undefined => {
  return useMemo(() => {

    return approvalSet?.map((it) => {
      const existingAttachments = it?.attachments as unknown as { data: { id: string }[] };
      return {
        uuid: it?.uuid,
        baseInitialItemUuid: it?.baseInitialItemUuid,
        name: it?.name,
        approvalDocumentType: it?.approvalDocumentType,
        status: it?.status ?? 'new',
        approved: it?.approved,
        attachments: existingAttachments?.data?.map((attachment) => attachment.id),
        company: it?.company?.data?.id,
        description: it?.description,
        expiryDate: it?.expiryDate ? moment(it?.expiryDate).format('YYYY-MM-DD') : null,
        feedback: it?.feedback ? moment(it?.feedback).format('YYYY-MM-DDTHH:mm:ssZ') : null,
        isAffected: it?.isAffected,
        isSiteVisitRequired: it?.isSiteVisitRequired,
      } as unknown as ComponentDataApplicationServiceProviderInput;
    }) || [];
  }, [approvalSet]);
};

export default useApprovalSetCopy;
