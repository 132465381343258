import { FC } from 'react'

import Typography from '../typography/Typography'
import './FormItemDivider.less'

const { Text, Title } = Typography
type Props = {
  label?: string
  subTitle?: Maybe<string>
  marginTop?: string | number
  marginBottom?: string | number
  title?: string
  titleLevel?: 1 | 2| 3| 4| 5
  extra?: React.ReactNode
}
const FormItemDivider: FC<Props> = ({extra, titleLevel = 3, label, marginBottom = 10, marginTop = 8, subTitle, title }) => {
  return (
    <div
      className={'form-sub-title-divider'}
      style={{
        marginTop: Number(marginTop),
        marginBottom: Number(marginBottom),
      }}
    >
      <div className={'label-wrapper'}>
        <div className={'label-container'}>
          {label && <Text weight={'w700'} size={'md'}>{label}</Text>}
          {title && <Title level={titleLevel} style={{marginBottom: 0}}>{title}</Title>}
        </div>
        {extra && (
          <div className={'extra-wrapper'}>
            {extra}
          </div>
        )}
      </div>

      {subTitle && (
        <Text weight={'w400'} size={'sm'} className={'sub-title'} color={'light-gray'}>
          {subTitle}
        </Text>
      )}
    </div>
  )
}

export default FormItemDivider
