import AddApprovalItem from '@/components/modal/modal-content/AddApprovalItem'
import ApplyForRenewal from '@/components/modal/modal-content/ApplyForRenewal'
import ArchiveApplication from '@/components/modal/modal-content/ArchiveApplication'
import ArchiveProject from '@/components/modal/modal-content/ArchiveProject'
import DeleteGeometryItem from '@/components/modal/modal-content/DeleteGeometryItem'
import GeometrySettings from '@/components/modal/modal-content/GeometrySettings'
import CompanyInfo from '@/components/modal/modal-content/CompanyInfo'
import ConfirmDeliveryApproval from '@/components/modal/modal-content/ConfirmDeliveryApproval'
import CreateCertificate from '@/components/modal/modal-content/CreateCertificate'
import CreateKickoffInvite from '@/components/modal/modal-content/CreateKickoffInvite'
import CreateKickoffRecord from '@/components/modal/modal-content/CreateKickoffRecord'
import CreateUserProfile from '@/components/modal/modal-content/CreateUserProfile'
import DeleteApplication from '@/components/modal/modal-content/DeleteApplication'
import DeleteApprovalItem from '@/components/modal/modal-content/DeleteApprovalItemConfirmation'
import DeleteCompletionCertificate from '@/components/modal/modal-content/DeleteCompletionCertificate'
import DeleteTask from '@/components/modal/modal-content/DeleteTask'
import DeliveryNote from '@/components/modal/modal-content/DeliveryNote'
import DuplicateApplication from '@/components/modal/modal-content/DuplicateApplication'
import FollowUp from '@/components/modal/modal-content/FollowUp'
import InvoiceApprove from '@/components/modal/modal-content/InvoiceApprove'
import RestoreApplication from '@/components/modal/modal-content/RestoreApplication'
import RestoreProject from '@/components/modal/modal-content/RestoreProject'
import ResubmitApplication from '@/components/modal/modal-content/ResubmitApplication'
import UserProfileReview from '@/components/modal/modal-content/UserProfileReview'
import { FC } from 'react'
import ApprovalForm from '../../pages/application/content/approval-details/ApprovalForm'
import { useGeneralContext } from '../context/GeneralContext'
import ApplicationAssignConfirmation from './modal-content/ApplicationAssignConfirmation'
import ApplicationAssignStep01 from './modal-content/ApplicationAssignStep01'
import ApplicationAssignStep02 from './modal-content/ApplicationAssignStep02'
import CreateApplicationOrganizationInfo from './modal-content/CreateApplicationOrganizationInfo'
import CreatedProjectInfo from './modal-content/CreatedProjectInfo'
import ApplicationReport from './modal-content/ApplicationReport'
import AssignProject from './modal-content/AssignProject'
import AssignUser from './modal-content/AssignUser'
import ConfirmQuotation from './modal-content/ConfirmQuotation'
import CreateApplication from './modal-content/CreateApplication'
import CreateApplicationAdditionalInfo from './modal-content/CreateApplicationAdditionalInfo'
import CreateApplicationSuccess from './modal-content/CreateApplicationSuccess'
import CreateCompany from './modal-content/CreateCompany'
import CreatePaymentContent from './modal-content/CreatePaymentContent'
import CreateProjectSuccess from './modal-content/CreateProjectSuccess'
import CreateQuotationItem from './modal-content/CreateQuotationItem'
import DeleteProject from './modal-content/DeleteProject'
import GeneratedApplications from './modal-content/GeneratedApplications'
import MapSavingInfo from './modal-content/MapSavingInfo'
import PaymentApprove from './modal-content/PaymentApprove'
import PaymentOptions from './modal-content/PaymentOptions'
import PaymentSuccess from './modal-content/PaymentSuccess'
import ProvidersAttachment from './modal-content/ProvidersAttachment'
import RegisterSuccess from './modal-content/RegisterSuccess'
import RequestInfo from './modal-content/RequestInfo'
import SendQuotation from './modal-content/SendQuotation'
import SimpleModal from './modal-content/SimpleModal'
import UploadReceipt from './modal-content/UploadReceipt'
import SelectedProjectInfo from './modal-content/SelectedProjectInfo'
import DeleteKickoffRecord from './modal-content/DeleteKickoffRecord'
import DeleteKickoffInvite from './modal-content/DeleteKickoffInvite'
import AgentAssign from './modal-content/AgentAssign'
import AgentAssignNotification from './modal-content/SentAgentAssignNotification'
import ChangeAgentStatus from './modal-content/ChangeAgentStatus'
import AddDocumentType from './modal-content/AddRequiredDocument'

const ModalContent:FC = () => {
  const { modalType } = useGeneralContext()

  const modalContentGenerator = () => {
    switch (modalType) {
      case 'simple':
        return <SimpleModal />

      case 'delete-task':
        return <DeleteTask />

      case 'create-user':
        return <CreateUserProfile />

      case 'resubmit-application':
        return <ResubmitApplication />

      case 'company-info':
        return <CompanyInfo />

      case 'delete-geometry-item':
        return <DeleteGeometryItem />

      case 'follow-up':
        return <FollowUp />

      case 'apply-for-renewal':
        return <ApplyForRenewal />

      case 'add-approval-item':
        return <AddApprovalItem />

      case 'duplicate-application':
        return <DuplicateApplication />

      case 'remove-approval-item':
        return <DeleteApprovalItem />

      case 'add-document-type':
        return <AddDocumentType />

      case 'change-agent-status':
        return <ChangeAgentStatus />

      case 'agent-assign':
        return <AgentAssign />

      case 'agent-assign-notification':
        return <AgentAssignNotification />

      case 'delete-kickoff-invite':
        return <DeleteKickoffInvite />

      case 'delete-kickoff-record':
        return <DeleteKickoffRecord />

      case 'delete-completion-certificate':
        return <DeleteCompletionCertificate />

      case 'register-success':
        return <RegisterSuccess />

      case 'assign-project':
        return <AssignProject />

      case 'approve-application':
        return <ApprovalForm mode={'approval'} />

      case 'application-assign-confirmation':
        return <ApplicationAssignConfirmation />

      case 'providers-attach':
        return <ProvidersAttachment />

      case 'application-assign-step-01':
        return <ApplicationAssignStep01 />

      case 'application-assign-step-02':
        return <ApplicationAssignStep02 />

      case 'map-saving-info':
        return <MapSavingInfo />

      case 'application-report':
        return <ApplicationReport />

      case 'create-application':
        return <CreateApplication />

      case 'create-application-organization':
        return <CreateApplicationOrganizationInfo />

      case 'confirm-quotation':
        return <ConfirmQuotation />

      case 'create-payment':
        return <CreatePaymentContent />

      case 'create-application-success':
        return <CreateApplicationSuccess />

      case 'create-application-info':
        return <CreateApplicationAdditionalInfo />

      case 'application-info':
        return <CreatedProjectInfo />

      case 'selected-project-info':
        return <SelectedProjectInfo />

      case 'project-created-success':
        return <CreateProjectSuccess />

      case 'payment-options':
        return <PaymentOptions />

      case 'payment-success':
        return <PaymentSuccess />

      case 'request-info':
        return <RequestInfo />

      case 'upload-receipt':
        return <UploadReceipt />

      case 'generated-applications':
        return <GeneratedApplications />

      case 'payment-approve':
        return <PaymentApprove />

      case 'invoice-approve':
        return <InvoiceApprove />

      case 'send-quotation':
        return <SendQuotation />

      case 'assign-user':
        return <AssignUser />

      case 'create-company':
        return <CreateCompany />

      case 'user-profile-review':
        return <UserProfileReview />

      case 'create-quotation-item':
        return <CreateQuotationItem />

      case 'delete-project-confirmation':
        return <DeleteProject />

      case 'archive-project-confirmation':
        return <ArchiveProject />

      case 'archive-application-confirmation':
        return <ArchiveApplication />

      case 'restore-application-confirmation':
        return <RestoreApplication />

      case 'restore-project-confirmation':
        return <RestoreProject />

      case 'confirm-delivery-approval':
        return <ConfirmDeliveryApproval />

      case 'delivery-note':
        return <DeliveryNote />

      case 'geometry-settings':
        return <GeometrySettings/>
      
      case 'delete-application-confirmation':
        return <DeleteApplication />

      case 'create-completion-certificate':
        return <CreateCertificate />

      case 'create-kickoff-record':
        return <CreateKickoffRecord />

      case 'create-kickoff-invite':
        return <CreateKickoffInvite />

      default:
        return <p>{'No popup content ...'}</p>
    }
  }
  return <div className={'modal-content'}>{modalContentGenerator()}</div>
}

export default ModalContent
