import { PLATFORM_SETTINGS } from '@/components/app'
import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import ItemTag from '@/components/item-tag/ItemTag'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompaniesQuery, useSendCompaniesNotificationsMutation, useUpdateApplicationMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { message, Row, Skeleton, Space } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const ApplyForRenewal: FC = () => {
  const { user } = useAuth()
  const { setIsModalOpen } = useGeneralContext()

  const [companies, setCompanies] = useState<CompanyEntity[]>([])

  const [sendCompaniesNotifications, { loading: notificationIsSending }] = useSendCompaniesNotificationsMutation()
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const approvalSet = appData?.attributes?.approvalSet as (ComponentDataApplicationServiceProvider & { __typename: string })[]
  const daysToCheck = PLATFORM_SETTINGS.closeToExpireDays || 30
  const currentDate = moment()

  const outdatedApprovalItems = approvalSet?.filter(it => {
    const expiry = moment(it?.expiryDate)
    const isAlreadyExpired = currentDate.isSameOrAfter(expiry)
    const closeToExpiry = expiry?.diff(moment(currentDate), 'days') <= daysToCheck
    return (it?.approved && !it?.isRenewalApplied && (isAlreadyExpired || closeToExpiry))
  })

  const companiesIds = outdatedApprovalItems?.length ? outdatedApprovalItems?.map(it => it?.company?.data?.id) : []

  const { data, loading: companiesDataLoading } = useCompaniesQuery({
    fetchPolicy: globalFetchPolicy,
    skip: companiesIds?.length === 0,
    variables: { filter: { id: { in: companiesIds } }, pagination: { limit: -1 } },
  })
  const companiesData = data?.companies?.data as CompanyEntity[]


  useEffect(() => {
    setCompanies(companiesData)
  }, [companiesData])

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onConfirm = async () => {
    const selectedCompaniesIds = companies?.map(it => it.id) as string[]

    const newRenewals: ComponentDataApplicationServiceProviderInput[] = companies?.map(it => {
      const currentApproval = approvalSet?.find(item => item?.company?.data?.id === it?.id)
      return ({
        uuid: v4(),
        name: it?.attributes?.name,
        company: it?.id,
        baseInitialItemUuid: currentApproval?.uuid,
      } as ComponentDataApplicationServiceProviderInput)
    })

    const existingRenewals = appData?.attributes?.renewalSet as (ComponentDataApplicationServiceProvider & { __typename: string })[]
    const existingRenewalsCopy = existingRenewals?.map(({ id, __typename, company, contacts, feedback, expiryDate, attachments, ...rest }) => ({
      company: company?.data?.id,
      contacts: contacts?.data?.map(it => it?.id),
      feedback: feedback ? moment(feedback).format('YYYY-MM-DDTHH:mm:ssZ') : null,
      expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : null,
      attachments: attachments?.data?.map(item => item?.id),
      ...rest,
    })) || []

    const renewalsToSave = [...newRenewals, ...existingRenewalsCopy] as ComponentDataApplicationServiceProviderInput[]

    const approvalSetToSave = approvalSet?.map(({ id, __typename, company, isRenewalApplied, contacts, feedback, expiryDate, attachments, ...rest }) => {

      if (company?.data?.id && selectedCompaniesIds?.includes(company?.data?.id)) {
        return ({
          isRenewalApplied: true,
          company: company?.data?.id,
          contacts: contacts?.data?.map(it => it?.id),
          feedback: feedback ? moment(feedback).format('YYYY-MM-DDTHH:mm:ssZ') : null,
          expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : null,
          attachments: attachments?.data?.map(item => item?.id),
          ...rest,
        } as ComponentDataApplicationServiceProviderInput)
      } else {
        return ({
          isRenewalApplied: isRenewalApplied,
          company: company?.data?.id,
          contacts: contacts?.data?.map(it => it?.id),
          feedback: feedback ? moment(feedback).format('YYYY-MM-DDTHH:mm:ssZ') : null,
          expiryDate: expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : null,
          attachments: attachments?.data?.map(item => item?.id),
          ...rest,
        } as ComponentDataApplicationServiceProviderInput)
      }
    })

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          approvalSet: approvalSetToSave,
          renewalSet: renewalsToSave,
        },
      },
      onCompleted: () => {
        BlackNotification('Renewals created successfully. Application updated.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        message.error(error.message)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })

    selectedCompaniesIds && selectedCompaniesIds?.length && await sendCompaniesNotifications({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          companiesIds: selectedCompaniesIds,
          applicationId: appData?.id || '',
          organizationId: user?.organization?.data?.id || '',
          category: 'renewal',
          profileId: user?.userProfile?.data?.id || '',
        },
      },
      onCompleted: () => {
        BlackNotification('Notifications were sent successfully.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
    })
  }

  const onItemClick = (id: Maybe<string>) => {
    const filteredCompanies = companies?.filter(it => it?.id !== id)
    setCompanies(filteredCompanies)
  }

  return (
    <>
      <FormItemDivider title={'Apply for renewal'}
                       subTitle={'Below is a list of companies with expired approval items or approval items that will expire within the next 30 days.'} />
      {!companiesIds?.length ? <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>{'App approval items are up-to-date'}</Paragraph> : (
        <Paragraph style={{ marginTop: 12, marginBottom: 16 }} size={'sm'} weight={'w700'}>
          {`Notification email will be sent to all listed companies. Renewal items will be created under the corresponding tab.`}
        </Paragraph>
      )}
      {companiesDataLoading && (
        <Skeleton paragraph={{ rows: 5 }} loading={true} />
      )}
      <Space direction={'vertical'} className={'tag-list-container'}>
        {companies?.map(it => (
          <ItemTag
            key={it?.id}
            mainText={it?.attributes?.name}
            secondaryText={it?.attributes?.companyEmail}
            iconType={'envelop'}
            closable={true}
            onClick={onItemClick}
            itemId={it?.id}
          />
        ))}

      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
          loading={notificationIsSending || companiesDataLoading || appDataLoading || applicationUpdating}
        />
        <Button
          text={'Apply for renewal'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={notificationIsSending || companiesDataLoading || appDataLoading || applicationUpdating}
          icon={<SvgIcon type={'envelop'} />}
          isIconWhite={true}
        />
      </Row>
    </>
  )
}
export default ApplyForRenewal
