import { createContext, Dispatch, SetStateAction, useContext } from 'react'
type DrawerContentType =
  |'create-profile'
  |'create-company'
  |'company-info'
  |'profile-review'
  |'approval-review'
  |''
export type ModalTypes =
  | 'simple'
  | 'delete-task'
  | 'apply-for-renewal'
  | 'create-user'
  | 'follow-up'
  | 'resubmit-application'
  | 'company-info'
  | 'add-approval-item'
  | 'remove-approval-item'
  | 'register-success'
  | 'providers-attach'
  | 'create-application-info'
  | 'create-application-success'
  | 'create-application'
  | 'create-application-organization'
  | 'create-payment'
  | 'application-report'
  | 'application-assign-confirmation'
  | 'map-saving-info'
  | 'application-assign-step-01'
  | 'application-assign-step-02'
  | 'application-info'
  | 'selected-project-info'
  | 'project-created-success'
  | 'request-info'
  | 'approve-application'
  | 'payment-options'
  | 'payment-success'
  | 'payment-approve'
  | 'invoice-approve'
  | 'upload-receipt'
  | 'assign-project'
  | 'generated-applications'
  | 'assign-user'
  | 'create-company'
  | 'send-quotation'
  | 'confirm-quotation'
  | 'create-quotation-item'
  | 'user-profile-review'
  | 'delete-project-confirmation'
  | 'archive-project-confirmation'
  | 'archive-application-confirmation'
  | 'restore-application-confirmation'
  | 'restore-project-confirmation'
  | 'delete-application-confirmation'
  | 'create-kickoff-invite'
  | 'create-kickoff-record'
  | 'create-completion-certificate'
  | 'delete-kickoff-record'
  | 'delete-geometry-item'
  | 'delete-completion-certificate'
  | 'delete-kickoff-invite'
  | 'agent-assign'
  | 'agent-assign-notification'
  | 'change-agent-status'
  | 'change-geometry-category'
  | 'change-geometry-provinces'
  | 'change-geometry-status'
  | 'add-document-type'
  | 'duplicate-application'
  | 'confirm-delivery-approval'
  | 'delivery-note'
  | 'geometry-settings'
  | ''
type SimpleModalSettings = {
  buttonLink?: string
  buttonText?: string
  content: string
  title: string
  onClick?: () => void
}
export type UserLevelOfApproval = {
  userId: string
  level: string
}[]
type SidebarMode = 'default' | 'user-info' | 'app-info' | 'project-info' | 'project-overview'
export type SignUpMode = 'register' | 'create-agent' | 'create-customer'
export type PageViewMode = 'grid' | 'table'
export type ApprovalCategory = 'approval' | 'renewal'
type ContextProps = {
  createAppCurrentStep: number
  currentApplicationId: string
  currentCompanyId: string
  currentId: string
  currentApprovalItemId: string
  documentInfo: Record<string, string>
  isModalOpen: boolean
  isLoading: boolean
  isDrawerOpen: boolean
  drawerWidth: number | null
  isPaymentCreated: boolean
  isReadyForReview: boolean
  locationConfirmationFormStep: string
  modalSettings: SimpleModalSettings
  modalType: ModalTypes
  drawerType: DrawerContentType
  modalWidth: null | number
  setModalWidth: Dispatch<SetStateAction<number | null>>
  setDrawerWidth: Dispatch<SetStateAction<number | null>>
  modalTopOffset: null | number
  setModalTopOffset: Dispatch<SetStateAction<number | null>>
  setIsPaymentCreated: Dispatch<SetStateAction<boolean>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setIsReadyForReview: Dispatch<SetStateAction<boolean>>
  setCreateAppCurrentStep: Dispatch<SetStateAction<number>>
  setCurrentApplicationId: Dispatch<SetStateAction<string>>
  setCurrentCompanyId: Dispatch<SetStateAction<string>>
  setCurrentId: Dispatch<SetStateAction<string>>
  setCurrentApprovalItemId: Dispatch<SetStateAction<string>>
  setDocumentInfo: Dispatch<SetStateAction<Record<string, string>>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  setLocationConfirmationFormStep: Dispatch<SetStateAction<string>>
  setModalSettings: Dispatch<SetStateAction<SimpleModalSettings>>
  setModalType: Dispatch<SetStateAction<ModalTypes>>
  setDrawerType: Dispatch<SetStateAction<DrawerContentType>>
  setSidebarMode: Dispatch<SetStateAction<SidebarMode>>
  setSignUpMode: Dispatch<SetStateAction<SignUpMode>>
  setTriggerRefetch: Dispatch<SetStateAction<boolean | null>>
  sidebarMode: SidebarMode
  signUpMode: SignUpMode
  triggerRefetch: boolean | null
  userLevelOfApproval: UserLevelOfApproval
  setUserLevelOfApproval: Dispatch<SetStateAction<UserLevelOfApproval>>
  mode: string
  setMode: Dispatch<SetStateAction<string>>
  pageViewMode: PageViewMode
  setPageViewMode: Dispatch<SetStateAction<PageViewMode>>
  tabActiveKey: string
  setTabActiveKey: Dispatch<SetStateAction<string>>
  approvalCategory: ApprovalCategory
  setApprovalCategory: Dispatch<SetStateAction<ApprovalCategory>>
}
export const defaultValues: ContextProps = {
  sidebarMode: 'default',
  setSidebarMode: () => null,
  modalType: '',
  drawerType: '',
  modalWidth: null,
  drawerWidth: null,
  setModalWidth: () => null,
  setDrawerWidth: () => null,
  modalTopOffset: null,
  setModalTopOffset: () => null,
  setModalType: () => null,
  setDrawerType: () => null,
  isModalOpen: false,
  isDrawerOpen: false,
  isLoading: false,
  isReadyForReview: false,
  isPaymentCreated: false,
  setIsLoading: () => null,
  setIsPaymentCreated: () => null,
  setIsReadyForReview: () => null,
  setIsModalOpen: () => null,
  setIsDrawerOpen: () => null,
  locationConfirmationFormStep: '1',
  setLocationConfirmationFormStep: () => null,
  createAppCurrentStep: 1,
  setCreateAppCurrentStep: () => null,
  triggerRefetch: false,
  setTriggerRefetch: () => null,
  modalSettings: { title: '', content: '' },
  setModalSettings: () => null,
  currentApplicationId: '',
  currentCompanyId: '',
  setCurrentApplicationId: () => null,
  setCurrentCompanyId: () => null,
  currentId: '',
  currentApprovalItemId: '',
  setCurrentId: () => null,
  setCurrentApprovalItemId: () => null,
  documentInfo: {},
  setDocumentInfo: () => null,
  signUpMode: 'register',
  setSignUpMode: () => null,
  userLevelOfApproval: [],
  setUserLevelOfApproval: () => null,
  mode: '',
  setMode: () => null,
  pageViewMode: 'table',
  setPageViewMode: () => null,
  tabActiveKey: '1',
  setTabActiveKey: () => null,
  approvalCategory: 'approval',
  setApprovalCategory: () => null,
}
const GeneralContext = createContext(defaultValues)
const useGeneralContext = () => useContext(GeneralContext)

export { GeneralContext, useGeneralContext }
