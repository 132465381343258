import FormItem from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationQuery, useConfirmDeliveryApprovalItemMutation } from '@/graphql'
import { Checkbox, Form, message, Row, Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import { FC, useState } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text } = Typography

const ConfirmDeliveryApproval: FC = () => {
  const [form] = useForm()
  const { setIsModalOpen, currentApplicationId, currentApprovalItemId } = useGeneralContext()
  const [confirmDelivery, { loading }] = useConfirmDeliveryApprovalItemMutation()

  const [isDisabled, setIsDisabled] = useState(true)
  const { data, loading: appDataLoading } = useApplicationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentApplicationId },
  })
  const application = data?.application?.data as ApplicationEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    form.submit()
    const values: { deliveryDate: Date, deliveryNotes: string } = form?.getFieldsValue()
    await confirmDelivery({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          applicationId: currentApplicationId,
          approvalItemId: currentApprovalItemId,
          deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
          deliveryNotes: values.deliveryNotes,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Delivery confirmed successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsDisabled(!e.target.checked)
  }
  const initialValues = {
    deliveryDate: moment(),
  }
  return appDataLoading ? (
    <>
      <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active={true} paragraph={{ rows: 5 }} />
    </>
  ) : (
    <>
      <FormItemDivider marginBottom={24} title={'Confirm delivery'}
                       subTitle={`Confirm delivery of approval item for application ${application?.attributes?.title}`} />
      <Form initialValues={initialValues} layout={'vertical'} form={form}>
        <Row align={'middle'}>
          <Checkbox onChange={onCheckboxChange}>
            <Text>{'Confirm delivery'}</Text>
          </Checkbox>
        </Row>
        <FormItemDivider />
        <FormItem isShowDisabledDates={false} name={'deliveryDate'} type={'date-picker'} label={'Delivery date'} />
        <FormItem name={'deliveryNotes'} type={'textarea'} label={'Delivery notes'} placeholder={'Enter delivery notes'} />

      </Form>

      <Row justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={loading} />
        <Button text={'Confirm delivery'} btnType={'primary'} onClick={onConfirm} loading={loading} disabled={isDisabled} />
      </Row>
    </>
  )
}
export default ConfirmDeliveryApproval
