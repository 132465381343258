import FormItemDivider from '@/components/form-item/FormItemDivider'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import LongDashText from '@/components/long-dash-text-item/LongDashText'
import CheckBoxInfoItem from '@/components/modal/modal-content/components/CheckBoxInfoItem'
import UniversalInfoItem from '@/components/modal/modal-content/components/UniversalInfoItem'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import Status, { StatusType } from '@/components/status/Status'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import UserContactCard from '@/components/user-contact-card/UserContactCard'
import { useCompanyQuery } from '@/graphql'
import { removeUnderscores } from '@/helpers/removeUnderscores'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { getDateAndTime } from '@/utilities/utilities'
import { Checkbox, Col, Row, Space } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const ApprovalReview: FC = () => {
  const { setIsModalOpen, currentId, setIsDrawerOpen, currentApprovalItemId, approvalCategory } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationByUuid()

  const application = data?.applications?.data?.[0] as ApplicationEntity

  const currentApproveItem = (approvalCategory === 'approval' ? application?.attributes?.approvalSet : application?.attributes?.renewalSet)?.find(
    it => currentApprovalItemId === it?.id,
  ) as ComponentDataApplicationServiceProvider

  const onCancel = () => {
    setIsDrawerOpen(false)
  }
const feedback = getDateAndTime(currentApproveItem?.feedback)
const expiry = getDateAndTime(moment(currentApproveItem?.expiryDate).toDate())
  return (
    <>
      <FormItemDivider
        extra={<Status status={currentApproveItem?.status as StatusType} accented />}
        marginBottom={24}
        title={'Approval review'}
        subTitle={`Review approval details for application ${application?.attributes?.title}`}
      />

      <div className={'drawer-scroll-container'}>
        <Row>
          <Col span={24}>
            <CheckBoxInfoItem name={'Approved'} checked={currentApproveItem?.approved}/>
            <CheckBoxInfoItem name={'Affected'} checked={currentApproveItem?.isAffected}/>
            <CheckBoxInfoItem name={'Site visit required'} checked={currentApproveItem?.isSiteVisitRequired}/>
            <CheckBoxInfoItem name={'Is renewal applied'} checked={currentApproveItem?.isRenewalApplied}/>
            <FormItemDivider />
            <UniversalInfoItem name={'Description:'}>
              <Paragraph>{currentApproveItem?.description}</Paragraph>
            </UniversalInfoItem>

            <UniversalInfoItem name={'Feedback date:'}>
              <Paragraph>{feedback?.date}</Paragraph>
            </UniversalInfoItem>
            <UniversalInfoItem name={'Expiry date:'}>
              <Paragraph>{expiry?.date}</Paragraph>
            </UniversalInfoItem>
            <UniversalInfoItem name={'Company:'}>
              <Paragraph>{currentApproveItem?.company?.data?.attributes?.name}</Paragraph>
            </UniversalInfoItem>
            <UniversalInfoItem name={'Contacts:'}>
              <>
                {currentApproveItem?.contacts?.data?.length ? currentApproveItem?.contacts?.data?.map(it => (
                  <SelectUserItem  key={it?.id} isBordered name={it?.attributes?.fullName} email={it?.attributes?.primaryEmail} />
                )) : <LongDashText />}
              </>
            </UniversalInfoItem>
            <UniversalInfoItem name={'Attachments:'}>
              <>
                {currentApproveItem?.attachments?.data?.length ? currentApproveItem?.attachments?.data?.map(it => (
                  <ImageFileCard marginBottom={8} key={it?.id} name={it?.attributes?.name ?? 'image'} isBordered imageUrl={it?.attributes?.url} />
                )) : <LongDashText />}
              </>
            </UniversalInfoItem>

          </Col>
        </Row>
      </div>



      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Close'}
          btnType={'primary'}
          onClick={onCancel}
          loading={appDataLoading}
        />
      </Row>
    </>
  )
}
export default ApprovalReview
